@import '../../assets/styles/sass/general.scss';

.accordion {
    width: 100%;
    margin: 2vw 0 3.5vw;
    border-top: 0.12vw solid #E2E2E2;
    @include respond-to('xs') {
        margin: 6vw auto;
    }
}

.accordion__block {
    border-bottom: 0.12vw solid #E2E2E2;
}

.accordion__item {
    cursor: pointer;
    display: flex;
    padding: 0.5vw 1vw;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &_active {
        background: #f6f6f6;
    }
    @include respond-to('xs') {
        margin: 2vw auto;
        padding: 0 6vw;
    }
}
.accordion__item:hover{
    opacity: 0.8;
}

.accordion__item-title {
    padding-right: 8vw;
    letter-spacing: 0.04vw;
    font: normal 1.4vw ExoBold;
    color: #071235;
    @include respond-to('xs') {
        padding-right: 6vw;
        letter-spacing: 0.04vw;
        font: normal 4vw ExoBold;
    }
}

.accordion__item-button {
    font: normal 2vw ExoRegular;
    color: #071235;
    @include respond-to('xs') {
        font: normal 8vw ExoRegular;
    }
}

.accordion__item-description {
    letter-spacing: 0.04vw;
    font: normal 1.4vw Arial;
    color: #606060;
    transition: 0.5s;
    padding: 0 1vw;
    height: 0;
    overflow: hidden;
    opacity: 0;
    word-wrap: break-word;
    &_active {
        opacity: 1;
        transition: 0.5s;
        padding: 1vw;
        height: auto;
        overflow: visible;
    }

    b {
        font: bold normal 1.4vw Arial;
        color: #606060;
    }

    a {
        letter-spacing: 0.04vw;
        font: normal 1.4vw Arial;
        color: #071235;
        text-decoration: underline;
    }
    ul {
        list-style-type: disc;
        list-style-position: inside;
    }

    @include respond-to('xs') {
        font: normal 4vw Arial;
        padding: 2vw 6vw;
        a {
            font: normal 4vw Arial;
            max-width: 80%;
        }
        b {
            font: bold normal 4vw Arial;
        }
    }
}

.accordion__decimal-list {
    li {
        display: flex;
        padding: 0.5vw 0;
    }
    b {
        font: bold normal 1.4vw Arial;
        color: #606060;
    }
    p {
        flex: 1;
    }
    p:first-of-type {
        flex: 0 1 30px;
    }
    @include respond-to('xs') {
        li {
            padding: 2vw 0;
        }
        p:first-of-type {
            flex: 0 1 5vw;
        }
        b {
            font: bold normal 4vw Arial;
        }
    }
}