$root_path: '../../assets';
$cdn: 'https://cdn.merchx.com';

$breakpoints: (
    'xl': (max-width: 1899px),
    'lg': (max-width: 1599px),
    'md': (max-width: 1199px),
    'sm': (max-width: 959px),
    'xs': (max-width: 730px),
    'xxs': (max-width: 499px)
);

$breakpoints_min: (
    'lg': (min-width: 1319px)
);

$fonts: (
    'ExoRegular': 'fonts/Exo/ExoRegular/ExoRegular',
    'ExoBold': 'fonts/Exo/ExoBold/ExoBold'
);

$container-max-width: 1200px;

$preloader-colors: #d7d7d7, #4fc0ea, #4fc0ea, #4fc0ea;

$preloader-dashoffset: 175.6449737548828;
