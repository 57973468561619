@import '../../assets/styles/sass/general.scss';

.logo_light {
    width: 6vw;
    height: 1.5vw;
    margin: 1.5vw auto;
    @include respond-to('xs') {
        width: 17vw;
        height: 4vw;
        margin: 4vw auto;
    }
}