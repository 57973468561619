@import '../../../styles/sass/general.scss';

.common_page {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.common_page-wrapper {
    width: 54%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #071235;
    @include respond-to('xs') {
        width: 90%;
    }
}

.common_page-descr_sm {
    margin-top: 2vw;
    font: normal 0.9vw Arial;
    letter-spacing: 0.01vw;
    color: #606060;
    @include respond-to('xs') {
        font: normal 2.8vw Arial;
        letter-spacing: 0.15vw;
        line-height: 3.5vw;
    }
}

.common_page-text {
    margin-top: 2vw;
    letter-spacing: 0.04vw;
    font: normal 1.4vw Arial;
    color: #606060;
    @include respond-to('xs') {
        margin-top: 6vw;
        font: normal 4vw Arial;
        letter-spacing: 0.2vw;
    }
}

.common_page-link {
    letter-spacing: 0.04vw;
    font: normal 1.4vw Arial;
    color: #071235;
    text-decoration: underline;
    @include respond-to('xs') {
        font: normal 4vw Arial;
        letter-spacing: 0.2vw;
    }
}

.common_page-link:hover {
    cursor: pointer;
    opacity: 0.9;
}
