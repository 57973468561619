@import '../../assets/styles/sass/general.scss';

.footer {
    position: relative;
    margin-top: auto;
    bottom: 0;
    width: 100%;
    background: #F9F7F7;
    padding: 2vw;
    display: flex;
    justify-content: center;
    @include respond-to('xs') {
        padding: 6vw 0;
    }
}

.footer_links-line {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_link {
    margin: 0 2vw;
    letter-spacing: 0.04vw;
    font: normal 0.75vw Arial;
    text-decoration: none;
    color: #071235;
    white-space: nowrap;
    @include respond-to('xs') {
        letter-spacing: 0.1vw;
        font: normal 2.8vw Arial;
        margin: 0 4vw;
    }
}