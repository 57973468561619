@import '../../assets/styles/sass/general.scss';


.home_button {
    background: #071235;
    border: none;
    border-radius: 0.2vw;
    letter-spacing: 0.04vw;
    font: bold normal 0.7vw Arial;
    padding: 1vw 2.2vw;
    color: #fff;
    outline: none;
    cursor: pointer;
    @include respond-to('xs') {
        width: 100%;
        font: normal 3.5vw Arial;
        padding: 4vw 3vw;
        border-radius: 1vw;
    }
}

.home_button:hover {
    opacity: 0.8;
}