@import '../../assets/styles/sass/general.scss';

.home {
    background: url('#{$root_path}/images/home_bg.png') no-repeat top center;
    background-size: contain;
    background-position-y: -4vw;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to('xs') {
        text-align: center;
        background-size: 290vw;
        background-position-x: -180vw;
    }
}

.home_wrapper {
    width: 57%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #071235;
    @include respond-to('xs') {
        width: 90%;
    }
}

.home_heading-bold {
    font: normal 3.5vw ExoBold;
    margin-top: 2vw;
    @include respond-to('xs') {
        font: normal 12vw ExoBold;
        margin: 4vw;
    }
}

.home_heading {
    font: normal 2.2vw Arial;
    @include respond-to('xs') {
        font: normal 6vw Arial;
    }
}

.home_top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_form {
    width: 100%;
    margin: 2vw 0 1.5vw;
    display: flex;
    justify-content: center;
    @include respond-to('xs') {
        order: 1;
        flex-direction: column;
        margin: 3vw auto;
    }
}

.home_form-input {
    width: 38%;
    background: #E2E2E2;
    border: none;
    border-radius: 0.2vw;
    letter-spacing: 0.04vw;
    font: normal 0.75vw Arial;
    padding: 1vw;
    margin-right: 1vw;
    color: #3E474D;
    outline: none;
    @include respond-to('xs') {
        width: 100%;
        margin: 3vw auto;
        font: normal 4vw Arial;
        padding: 3.5vw 3vw;
        border-radius: 1vw;
    }

    &_error {
        border: 0.4vw solid rgba(255, 107, 108, 0.69);
    }
}

.home_heading-descr {
    font: normal 0.7vw Arial;
    letter-spacing: 0.02vw;
    width: 60%;
    text-align: center;
    @include respond-to('xs') {
        order: 1;
        font: normal 3vw Arial;
        letter-spacing: 0.1vw;
        line-height: 4vw;
        width: 84%;
    }
}

.home_payment-img {
    width: 48%;
    max-width: 48%;
    margin-top: 8vw;
    @include respond-to('xs') {
        width: 62%;
        max-width: 62%;
        margin-top: 4vw;
    }
}

.home_features {
    width: 100%;
    margin-top: 14vw;
    display: flex;
    justify-content: space-between;
    @include respond-to('xs') {
       flex-direction: column;
    }
}

.home_features-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 31%;
    @include respond-to('xs') {
        width: 100%;
        margin-bottom: 15vw;
    }
}

.home_features-icon {
    width: 2.2vw;
    @include respond-to('xs') {
        width: 8vw;
    }
}

.home_features-name {
    font: normal 1vw ExoBold;
    margin: 1vw 0;
    letter-spacing: 0.03vw;
    @include respond-to('xs') {
        font: normal 4vw ExoBold;
        margin: 3vw 0;
        letter-spacing: 0.25vw;
        text-align: left;
    }
}

.home_features-descr {
    font: normal 0.9vw Arial;
    letter-spacing: 0.02vw;
    @include respond-to('xs') {
        font: normal 3.5vw Arial;
        letter-spacing: 0.1vw;
        text-align: left;
    }
}

.home_brands {
    width: 100%;
    border-top: 0.12vw solid #E2E2E2;
    border-bottom: 0.12vw solid #E2E2E2;
    margin: 7vw 0 6vw;
    @include respond-to('xs') {
        display: flex;
        flex-direction: column-reverse;
        padding: 6vw 0 4vw;
    }
}

.home_brands-line {
    padding: 2vw 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-to('xs') {
        flex-wrap: wrap;
        justify-content: start;
    }
}

.home_brands-icon {
    width: 7vw;
    @include respond-to('xs') {
        width: 18vw;
        margin-left: 3vw;
    }
}

.home_brands-descr {
    font: normal 1vw ExoBold;
    letter-spacing: 0.03vw;
    text-align: center;
    margin-bottom: 1.5vw;
    @include respond-to('xs') {
        font: normal 4vw ExoBold;
        letter-spacing: 0.1vw;
    }
}

.home_end {
    width: 74%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 5vw;
    @include respond-to('xs') {
        width: 100%;
    }
}

.home_end-quote {
    font: normal 1.5vw ExoBold;
    letter-spacing: 0.02vw;
    @include respond-to('xs') {
        font: normal 6vw ExoBold;
        letter-spacing: 0.1vw;
    }
}

.home_end-author {
    letter-spacing: 0.04vw;
    font: normal 0.75vw Arial;
    text-transform: uppercase;
    margin: 0.8vw 0 2vw;
    @include respond-to('xs') {
        letter-spacing: 0.08vw;
        font: normal 3vw Arial;
        margin: 5vw 0 6vw;
    }
}






//.home {
//    color: red;
//    @include respond-to('xs') {
//        color: green;
//    }
//}