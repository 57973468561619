@import '../../assets/styles/sass/general.scss';

.header {
    width: 100%;
    background: #071235;
    height: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include respond-to('xs') {
        height: auto;
    }
}

.header_wrapper {
    width: 54%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    @include respond-to('xs') {
        width: 90%;
        align-items: center;
        text-align: center;
        margin: 4vw 0 8vw;
    }
}

.header_heading {
    font: normal 3vw ExoBold;
    @include respond-to('xs') {
        font: normal 12vw ExoBold;
    }
}
